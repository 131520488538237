// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-layouts-blog-category-js": () => import("./../src/layouts/blog-category.js" /* webpackChunkName: "component---src-layouts-blog-category-js" */),
  "component---src-layouts-blog-list-js": () => import("./../src/layouts/blog-list.js" /* webpackChunkName: "component---src-layouts-blog-list-js" */),
  "component---src-layouts-blog-post-js": () => import("./../src/layouts/blog-post.js" /* webpackChunkName: "component---src-layouts-blog-post-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-home-js": () => import("./../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

