/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "./src/styles/index.scss"

// Google metadata verification
// <meta name="google-site-verification" content="cAuWGVzLrBBP8eTQ52dMvBVoMa7fnPmieKjrEMTA5c0" />
